@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito:wght@400;700&display=swap');

.dashboard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 30px 50px;
  }

 

  .stripe-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 30px 50px;
  }
  
  .dashboard-box {
  width: 90%;
  min-height: 400px;
  background-color: #FFFFFF;
  border-radius: 50px;
  padding: 30px 50px 50px;
  
  flex-direction: column;
  margin-top: 100px; /* Increase top margin to push the box down */

  }

  .forms-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 30px 50px;
  }
  /* This is the contents of the white form  */
  .forms-style {
    display: block;
    color: #2c3e50;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    align-items: center;
  }


  .accounts-container {
    width: 200%;
    display: fixed;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 30px 50px;
  }

  /* Two fields per row */
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adds space between fields */
  margin-bottom: 15px;
}

.form-group {
  flex: 1; /* Ensures both fields take equal space */
  display: flex;
  flex-direction: column; /* Ensures label is above field */
}
  
  .accounts-box {
    width: 300%;
    max-width: 600px; /* Ensures the form is centered and has a reasonable width */
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 30px;
    margin-top: 120px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Ensure all input fields are the same size */
  .accounts-style input,
  .accounts-style textarea,
  .accounts-style select {
    width: 70%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  /* Ensure labels are on top of fields */
  .accounts-style label {
    display: block;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 3px;
    color: #2c3e50;
  }
  
  /* Style the form headings */
  .accounts-box h2 {
    color: #fc8320;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
  }
  
  /* Style the form rows for better spacing */
  .form-group {
    margin-bottom: 10px;
  }

  /* Center the button */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
  
  /* Style the submit button */
  .accounts-style button {
    width: 50%;
    padding: 12px;
    background-color: #fc8320;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .accounts-style button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Error message styling */
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .forms-box {
    width: 60%;
    min-height: 400px;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 30px 50px 50px;
    
    flex-direction: column;
    margin-top: 100px; /* Increase top margin to push the box down */
  
    }

    /* Ensure labels are on top of fields */
.forms-style label {
  display: block;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
  color: #2c3e50;
}

/* Ensure all input fields are even in width and height */
.forms-style input,
.forms-style ,
.forms-style textarea,
.forms-style select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 15px;
}
.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
 
}

  /* This is for the white box background*/
  .stripe-box {
    width: 90%;
    min-height: 400px;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 30px 50px 50px;
    
    flex-direction: column;
    margin-top: 25%; /* Increase top margin to push the box down */
  
    }

  .dashboard-box h2 {
    color: #fc8320;
    font-size: 29px;
    font-weight: 400px;
  text-align: center;
    }
  
    .stripe-box h2 {
      color: #fc8320;
      font-size: 29px;
      font-weight: 400px;
    text-align: center;
      }

      .forms-box h2 {
        color: #fc8320;
        font-size: 29px;
        font-weight: 400px;
      text-align: center;
        }
        .forms-box h3 {
      
          font-size: 20px;
          font-weight: 300px;
        text-align: center;
          }

      .form-style vertical-stack {
   
        flex-direction: column;
        margin-top: 10px; /* Adjust as needed */
      }
 
      /* Center the button */
.forms-style .button-container {
  display: flex;
 
  align-items: center; /* Aligns vertically */
  width: 100%;
  margin-top: 15px; /* Adds spacing above the button */
}
      /* Adjust button styles */
.forms-style button {
  width: 50%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #951eb5;
  border: none;
  border-radius: 5px;
 cursor: pointer;
  transition: background-color 0.3s;
  justify-content: center; /* Centers horizontally */
}

.forms-style button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.forms-style button:hover:not(:disabled) {
  background-color: #fc8320;
}


  
  .greeting-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .greeting-text {
    opacity: 0.5;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .button-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .dashboard-button {
  width: 100%; /* Decrease width */
  height: 200px; /* Decrease height */
  display: flex;
  flex-direction: column;
  justify-content: center; 
  font-family: 'Nunito', sans-serif;
  color: #FFFFFF;
  font-size: 25px;
  font-weight: 700;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  border: 3px solid #EEEEEE;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #fc8320;
  cursor: pointer;
  margin-right: 20px; /* Adjust margin as needed */
 
  }
  
  .dashboard-button:hover {
    border: 3px solid #fc8320;
    background-color: #951eb5;
    color: #fbfafc ;
  }
  
  /* Add additional styles for specific buttons if needed */

  
  
  /* ... Add more classes for other buttons and elements as needed ... */
  

  
  .button-image, .button-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .button-image {
    top: 10%; /* Adjust as needed */
    width: auto; /* or a specific width */
    height: auto; /* or a specific height */
  }
  
  .button-text {
    bottom: 10%; /* Adjust as needed */
    /* Text styling */
  }

  /* Responsive: Switch to one column on smaller screens */
@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 10px;
  }
}



/* Styling for a loading spinner */


.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust size as needed */
}

.loading-spinner {
    border: 5px solid #f3f3f3; /* Light gray */
    border-top: 5px solid #951eb5; /* Purple */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

 /* Includes styling for Navbar, Sign up button, login button, modal, and Hamburger menu*/

   /* Navigation*/


   .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff; /* Semi-transparent white background */
   
  }
  
  .logo {
    height: 40px; /* Reduced size of the logo */
    margin-left: 20px; /* Add space from the left edge of the page */
    margin-top: 20px; /* Adjust top margin to lower the logo */
  }
  
        /* Navigation Avatar and Dropdown Menu Styles */
  
  
  .nav-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 20px; /* Spacing between login button and avatar */
    margin-top: 18px;
  }
  
  .nav-dropdown {   /* styling for dropdown menu*/
    position: absolute;
    right: 20px; /* Adjust if needed to align with the avatar */
    top: 70px; /* Set the top position to place it below the avatar */
    background-color: #f9f9f9;
    min-width: 180px;
    height: flex;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    overflow-y: visible;
  }
  
  .nav-dropdown a {  /* styling for text in dropdown menu*/
    color: #2c3e50;
    padding: 12px 12px;
    text-decoration: none;
    display: block;
    margin-bottom: none;
  }
  
  .nav-dropdown a:hover {
    background-color: #fc8320;
    color: #ffffff;
   
  }
  
  .sign-out-link {  /* styling for sign out in dropdown menu*/
    background: none; 
    padding: 12px 16px;
    display: block;
    color: #fc8320; 
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    border: none;
   
  }
 
  
        /* Log In Button in Navigation */
  
  
  .login-button {
    padding:  10px 15px;
    background-color: #fc8320; /* Change as per your color scheme */
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: block;
    
  }
  
  .login-button:hover {
    background-color: #951eb5;
  }

 
  
          /* Sign Up Button in Navigation */
  
  
  .signup-button {
    display: flex;
    padding:  10px 15px;
    background-color: #951eb5; /* Change as per your color scheme */
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: block;
    
  }
  
  .signup-button:hover {
    background-color: #fc8320;
  }

      /* Hamburger not displayed in desktopNavigation */

  .hamburger,  .line,    .menu{
    display: none;
    pointer-events: none; /* Make it non-interactive */
  }

/*   MODAL desktop*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .modal-content { /*   MODAL text and size of box*/
    background-color: white;
    padding: 20px;
    color: #2c3e50;
    font-size: 20px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    height: 260px;
    width: 400px;
    height: auto;
  }

  /* ✅ Add close button styling */
.modal-close {
  background: #d21dd2;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
}

.modal-close:hover {
  background: #951eb5;
}
  
  .close-button { /*   MODAL close button desktop*/
    background-color: #d21dd2;
    color: white;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    width: 20%;

  }
  
  .confirmout-button {
    background-color: #fc8320;
    margin-top: 7%;
    margin-bottom: 15%;
    color: white;
    font-size: 16px;
    border: none;
    font-weight: 500px;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
  }

  .confirmout-button:hover {
    background-color: #951eb5;
  }

  .close-button:hover {
    background-color: #2c3e50;
  }

  

   /* Media Queries */


      /* Adjust the logo size for smaller screens */

      @media only screen and (max-width: 480px) {

            .logo {
                height: 25px;
                margin-left: 5%;
                margin-top: 20px;
                margin-right: 12%; /* Adjust logo position */
            }

   

          .hamburger {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30px; /* Width of the hamburger */
            height: 25px; /* Height of the hamburger */
            }

            .line {
              height: 3px;
              background-color: black; /* Color of the hamburger lines */
              margin: 3px 0;
              }
              
              .menu {
              /* Styles for your menu */
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 35px; /* Adjust as needed */
              left: 0;
              background: white; /* Background of the menu */
              width: 100%; /* Full width */
              /* Add more styles (like padding) as needed */
              }

              .login-button { /* this is a media query  */

                padding: 10px;
                margin-left: 1%; /* Add space from the left edge of the page */
                width: 20%;
                font-size: 12px;
                
                }
        
                .signup-button {  /* this is a media query  */
        
                  padding: 10px;
                  margin-left: 1%; /* Add space from the left edge of the page */
                  width: 22%;
                  border: none;
                  border-radius: 4px;
                
                  font-size: 12px;
                
                  }

                  .nav-dropdown {   /* styling for dropdown menu*/
                    position: absolute;
                    min-width: 140px;
                    overflow-y: visible;
                  }


      }
      

      /* Tablet view adjustments */
      @media only screen and (min-width: 481px) and (max-width: 640px) {

          .logo {
              height: 40px;
              margin-left: auto;
              margin-top: 20px;
          }

          .nav-dropdown {   /* styling for dropdown menu*/
            position: absolute;
            min-width: 140px;
            overflow-y: visible;
          }
          

          


          .hamburger {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30px; /* Width of the hamburger */
            height: 25px; /* Height of the hamburger */
            }

            .line {
              height: 3px;
              background-color: black; /* Color of the hamburger lines */
              margin: 3px 0;
              }
              
              .menu {
              /* Styles for your menu */
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 35px; /* Adjust as needed */
              left: 0;
              background: white; /* Background of the menu */
              width: 100%; /* Full width */
              /* Add more styles (like padding) as needed */
              }
      }

      /* Larger screens */
      @media only screen and (min-width: 1024px) and (max-width: 1366px) {
        .login-button, .signup-button {
          padding:  10px 15px;
          background-color: #fc8320; /* Change as per your color scheme */
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          border: none;
          border-radius: 6px;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          display: block;
        }

        .hamburger, .line, .menu {
          display: none; /* Ensure hamburger menu is hidden in desktop view */
      }
      }
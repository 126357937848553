
/* design for SignUpB, SignUpC page */
  
.questionnaire-container {
  width: 400px; /* Fixed width */
    height: 100%; /* Fixed maximum height */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 50px;
    padding-bottom: 10px;
    
    display: flex;
    flex-direction: column;
    margin-top: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  /* This is the contents of the white form  */
  .questionnaire-style {
    display: block;
    color: #2c3e50;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    align-items: center;
  }
  
  .questionnaire-style vertical-stack {
   
    flex-direction: column;
    margin-top: 10px; /* Adjust as needed */
  }
  
    /* heading Create Account */

  .questionnaire-style h2 {
    text-align: center;
    font-size: 20px;
    color: #fc8320;
  }

  .questionnaire-style p {
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    color: #2c3e50;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  /* This is for the subtext below the heading */
  .questionnaire-style-heading {
    text-align: center;
    color: #2c3e50;
    font-family: 'Nunito Sans', sans-serif;
    
  }

 /* These are the fields in the form*/

  .questionnaire-style input[type="text"], 
  .questionnaire-style input[type="password"], 
  .questionnaire-style input[type="url"],
  .questionnaire-style input[type="email"],
  .questionnaire-style input[type="website"],
  .questionnaire-style select  {
    width: 100%;
    padding: 10px;
    margin-top: 2px; /* margin above the field */
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 16px;
    color: #2c3e50;
  }

 

  .questionnaire-style input[placeholder] {
   font-size: 12px;
    font-weight: 100;
    color: #2c3e50;
  }


  /* Add styles for dropdown fields to match the input fields */

  

.scrollable-content {
    max-height: 400px;
    overflow-y: auto;
   
  }

  
 .questionnaire-style button {
  padding: 14px;
  background-color: #fc8320; /* Example primary color */
  color: white;
  font-family: 'NunitoSans', sans-serif;   
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 40%; 
  display: block;
  margin-left: 30%;
  }
  
  .questionnaire-style button:hover {
    background-color: #951eb5; /* Darker shade for hover effect */
  }
  
   /* NOTE: Terms and conditions  */
  .cta-links {
    font-size: 12px;
    font-family: 'Nunito', sans-serif;
    margin-top: 5px; 
  
  }
  
  .cta-links a {
  
    color: #fc8320;
    font-size: 12px;
  
  
  }

    /* MEDIA QUERIES */

  @media only screen and (max-width: 480px) { /* example Galaxy S5 + Iphone SE */
  
    .questionnaire-container {
      width: 60%;
      max-height: 500px; /* Fixed maximum height */
      background-color: #FFFFFF;
      border-radius: 20px;
      padding: 50px;
      overflow-y: auto; /* Enable vertical scrolling */
      display: flex;
      flex-direction: column;
      margin-top: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  
    .questionnaire-style button {
      padding: 10px;
      background-color: #fc8320; /* Example primary color */
      color: white;
      border: none;
      border-radius: 12px;
      font-family: 'NunitoSans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 30px;
      cursor: pointer;
      display: inline-block;
    }
  
  }
  
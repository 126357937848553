

/* ✅ Main container for admin dashboard */
.admin-dashboard {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* ✅ Header styling */
  .admin-dashboard h2 {
    text-align: center;
    color: #333;
    font-size: 26px;
    margin-bottom: 20px;
  }
  
  /* ✅ Section for totals (cards layout) */
  .dashboard-totals {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }

  .dashboard-totals h3 {
   text-align: center;

  }
  
  .total-card {
    flex: 1;
    background: #f4f4f4;
    padding: 15px;
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .total-card p {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
  }
  
  /* ✅ Table styling */
  .admin-table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .admin-table th, .admin-table td {
    padding: 10px 12px;
    font-size: 14px;
    color: #2c3e50;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .admin-table th {
    background: #fc8320;
    color: white;
    font-weight: bold;
  }

  .admin-table p {
    font-size: 12px;
   
    font-weight: bold;
  }
  
  .admin-table tbody tr:hover {
    background: #f9f9f9;
  }
  
  /* ✅ Select dropdown styling */
  .role-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  /* ✅ Action buttons */
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .btn {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .btn-delete {
    background: #ff4d4d;
    color: white;
  }
  
  .btn-delete:hover {
    background: #cc0000;
  }
  
  .btn-promote {
    background: #4caf50;
    color: white;
  }
  
  .btn-promote:hover {
    background: #388e3c;
  }
  
  /* ✅ Mobile Responsiveness */
  @media (max-width: 768px) {
    .dashboard-totals {
      flex-direction: column;
    }
  
    .admin-table th, .admin-table td {
      padding: 10px;
    }
  
    .action-buttons {
      flex-direction: column;
    }
  }
  
  
  /* design for any apage

 /* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito:wght@400;700&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  background: #951eb5 no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px; /* Add bottom padding */
}

  /* Style for other pages */

  .form-style { /* white background container */
    width: 400px; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-top: 30px; /* Consistent margin from top */
    margin-left: auto; /* Centering the form horizontally */
    margin-right: auto;
  }


  .wordpress-style { /* white background container */
    width: 600px; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-top: 30%; /* Consistent margin from top */
    margin-left: auto; /* Centering the form horizontally */
    margin-right: auto;
   
  }


  
  stripe-pricing-table {
    display: flex !important;
  justify-content: center !important;
 
  flex-direction: row !important; /* Forces horizontal layout */
  width: 100% !important;
  max-width: 1200px; /* Adjust width if necessary */
  }
 
  

  .form-style h2 {  /* heading for container.  */
    text-align: center;
    font-size: 20px;
    color: #fc8320;
  }

  .form-style h1 {  /* heading for container.  */
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #fc8320;
  }
  
  .form-style label { /* field labels  */
    display: block;
    margin-top: 10px;
    color: #2c3e50;
    text-align: left;
    margin-bottom: 3px;
    font-family: 'Nunito', sans-serif;
  }
  
  .form-style input[type="text"], 
  .form-style input[type="password"], 
  .form-style input[type="email"] {
    width: 90%;
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    margin: 10px 0;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-style button { /* for any submit button  */
    width: auto;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #fc8320;
    color: white;
    text-transform: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin: 20px auto;
  }
  
    .form-style button:hover {  /* hover for any submit button  */
      background-color: #951eb5;
    }

    .forgot-password-link { /* signin page  */
      font-size: 16px;
      font-family: 'Nunito', sans-serif;
      text-align: center;
      color: #fc8320;
      margin-top: 20px; 
    
    }

    .verify-message { /* email is verified */
      font-size: 50px;
      font-family: 'Nunito', sans-serif;
      text-align: center;
      color: #fc8320;
      margin-top: 5px; 
    
    }

    .email-style {
      font-size: 16px;
      font-family: 'Nunito', sans-serif;
      color: #fc8320;
      text-decoration: underline;
      font-weight: 400;
    
    }
    p {
      font-size: 16px;
      font-family: 'Nunito', sans-serif;
      text-align: center;
      color: #2c3e50;
      margin-top: 20px; 
    
    }
  

    .submit-button {
      padding: 14px;
      background-color: #fc8320; /* Example primary color */
      color: white;
      border: none;
      border-radius: 8px;
      font-family: 'NunitoSans', sans-serif;   
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      cursor: pointer;
    }


    .submit-button:hover {
      background-color: #951eb5; /* Darker shade for hover effect */
    }


   /* MEDIA QUERIES */

   @media only screen and (max-width: 480px) { 

    /* example Galaxy S5 + Iphone SE */
     /* Sign Up Home Container */

     .form-style { /* background */
      width: 100%; /* Fixed width */
      height: auto; /* Adjusted for variable content */
      display: flex;
      margin-top: 20px; /* Consistent margin from top */
    }


    .sign-home {
      margin-top: 125px;
      margin-left: 50px;
      margin-right: 70px;
      justify-content: center; /* Centers the content horizontally */
      align-items: start; /* Aligns items to the top */
      padding: 20px;
      flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
  }

        .container {
          display: flex;
          margin-top: 125px;
          margin-left: 100px;
          margin-right: 190px;
          justify-content: center; /* Centers the content horizontally */
          align-items: start; /* Aligns items to the top */
          padding: 20px;
          flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
      }

      .image-container {  /* media query */
          
        display: none;
        

    }

        .image-container img {  /* media query */
          display: none;
      }

        .custom-select { /* media query */
          padding: 10px;
          margin-top: 20px; /* Spacing between elements */
          margin-bottom: 20px; /* Spacing between elements */
          font-size: 16px;
          font-weight: 200;
        }
        
        .custom-select:hover { /* media query */
          background-color: #941eb51f;
        }



          .cta-links { /* media query */
          
            margin-top: 10px; 

      }

      .avatar-image {
        width: 20px;  /* Adjust the width as needed */
        height: 20px;  /* Adjust the height as needed */
        border-radius: 20%;  /* Makes the image circular */
        object-fit: contain;  /* Ensures the image covers the area without distortion */
      }
      
      .account-style {  /* Media query.  */
        width: 60%; /* Fixed width */ /* Fixed width */
        height: 100%; /* Adjusted for variable content */ /* Adjusted for variable content */
        border-radius: 20px;
        padding: 40px; /* Consistent padding */
        display: flex;
        flex-direction: column;
        margin-top: 170%;
        margin-left: auto; /* Centering the form horizontally */
        margin-right: auto;
    }
    
      .account-container {  /* Media query.  */
        max-width: 100px; /* example width */
        border-radius: 20px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    body 
    {
    min-height: 100%;
    padding-bottom: 600px; /* Add bottom padding */
    overflow-x: hidden;
  }
    

        .form-style h2 {  /* Media query.  */
          text-align: center;
          font-size: 20px;
    }
    
    .form-style label {  /* Media query.  */
      display: block;
      margin-top: 10px;
      font-size: 14px;
      color: #2c3e50;
    
    }
    
   
    
    .submit-button {  /* Media query.  */
      width: 70%;
      padding: 10px;
      margin-top: 10px;
      background-color: #fc8320;
      justify-content: center;
      
    }

    
    .account-style name-display label {  /* Media query.  */
      margin-right: 10px;
      font-size: 14px;
    }
    
    .account-style email-edit {  /* Media query.  */
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 10px;
    }
    

    
   }


   @media only screen and (min-width: 481px) and (max-width: 640px) {
 
    .container-style { /* background */
      width: 100%; /* Fixed width */
      height: auto; /* Adjusted for variable content */
      display: flex;
      margin-top: 20px; /* Consistent margin from top */
    }


    .sign-home {
      margin-top: 125px;
      margin-left: 50px;
      margin-right: 70px;
      justify-content: center; /* Centers the content horizontally */
      align-items: start; /* Aligns items to the top */
      padding: 20px;
      flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
  }

      .container {
        display: flex;
        margin-top: 125px;
        margin-left: 100px;
        margin-right: 190px;
        justify-content: center; /* Centers the content horizontally */
        align-items: start; /* Aligns items to the top */
        padding: 20px;
        flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
    }

    .image-container {  /* media query */
        
      display: none;
      

    }

      .image-container img {  /* media query */
        display: none;
    }

      .custom-select { /* media query */
        padding: 10px;
        margin-top: 20px; /* Spacing between elements */
        margin-bottom: 20px; /* Spacing between elements */
        font-size: 16px;
        font-weight: 200;
      }
      
      .custom-select:hover { /* media query */
        background-color: #941eb51f;
      }



        .cta-links { /* media query */
        
          margin-top: 10px; 

    }


      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {

        /* example iPad  */

      }


   @media only screen and (min-width: 1024px) and (max-width: 1366px) {


    /* Media query.  */

    .account-style {  /* Media query.  */
      width: 100%; /* Fixed width */
      height: 80%; /* Adjusted for variable content */
      border-radius: 20px;
      padding: 50px; /* Consistent padding */
      display: flex;
      flex-direction: column;
      margin-top: 100px; /* Consistent margin from top */
      margin-left: auto; /* Centering the form horizontally */
      margin-right: auto;
    }

    .account-style label {  /* Media query.  */
      display: block;
      margin-top: 10px;
      font-size: 20px;
      color: #2c3e50;
    
    }

    .account-style h2 {  /* Media query.  */
      text-align: center;
      font-size: 30px;
    }


   }




@media only screen and (min-width: 768px) and (max-width: 1023px) {

  /* example iPad  */


  .custom-select { /* Media query main */
    padding: 10px;
    margin-top: 20px; /* Spacing between elements */
    margin-bottom: 20px; /* Spacing between elements */
    background-color: #fc832030;
    border-radius: 10px;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: #2c3e50;
  }

  .image-container { /* Media query main */
    flex: 1; /* Takes the remaining space */
    display: flex;
    padding-top: 10;
    justify-content: center;
    align-items: center;
    max-width: 200px; /* Sets a maximum width to match the form */
    margin: 20px; /* Adds space around the image */
}

.image-container img { /* Media query main */
    max-width: 100%;
    margin-top: 0px; /* Consistent margin from top */
    margin-right: 150px; /* Consistent margin from top */
    height: 500px;
    max-height: 200px; /* Adjust to fit the design */
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
 

  .container {
    display: flex;
    margin-top: 100px;
    margin-left: 190px;
    justify-content: center; /* Centers the content horizontally */
    align-items: start; /* Aligns items to the top */
    padding: 20px;
    flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
}

  .custom-select {
    padding: 10px;
    margin-top: 15px; /* Spacing between elements */
    margin-bottom: 15px; /* Spacing between elements */
    background-color: #fc832030;
    border-radius: 10px;
    border: none;;
    font-size: 20px;
    font-weight: 200;
    color: #2c3e50;
  }

  .signup h2 {
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    font-size: 30px;
    color: #2c3e50;
  }

  p {
    font-size: 20px;
  }

 
}
  
  /* design for any account edit page and  Sign Up HOME page */


  /* Sign Home Container */
    /* SignHome.js */

  .sign-home {
      display: flex;
      margin-top: 10%;
      margin-left: 190px;
      justify-content: center; /* Centers the content horizontally */
      align-items: start; /* Aligns items to the top */
      padding: 20px;
      flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
  }

 /* This is the white form on the SignHome page */

  .container-style {
    width: 70%; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
   
    margin-left: none; /* Centering the form horizontally */
    margin-right: auto;
  }


  .container-logo {  
    height: 40px; /* Reduced size of the logo */
    margin-left: 40px; /* Add space from the left edge of the page */
    margin-top: none; /* Adjust top margin to lower the logo */
    align-items: center;
  }

   /* This is the Welcome to Neufluence */
  .container-style h2 {  
    text-align: center;
    font-size: 20px;
    color: #fc8320;
  }

.placeholder {
  font-weight: 100;

}
 /* This is the dropdown menu */

    .custom-select {
      padding: 15px;
      margin-top: 20px; /* Spacing between elements */
      margin-bottom: 15px; /* Spacing between elements */
      background-color: #fc832030;
      border-radius: 10px;
      border: none;
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      font-weight: 200;
      color: #2c3e50;
    }

    .custom-select:hover {
      background-color: #941eb51f;
    }

 /* This is the Create Account button */

 .signbutton-container {
  
  margin-bottom: none;
  display: inline-block;
}

.create-button {
  padding: 14px;
      background-color: #fc8320; /* Example primary color */
      color: white;
      font-family: 'NunitoSans', sans-serif;   
      font-size: 14px;
      font-weight: 600;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      width: 40%; 
      display: block;
      margin-left: 30%;
    
}

.create-button:hover {
  background-color: #951eb5; /* Darker shade for hover effect */
}




/* Terms and Privacy Policy*/

.term-links a {
  color: #fc8320; /* Replace with your desired color */
  margin-left: 3px; /* Adds space to the left of each link */
  font-size: 12px;
  margin-right: 3px;
}

.term-links  a:hover {
  text-decoration: underline; /* Replace with the color for hover state */
}

.term-links {
  font-size: 11px;
  font-family: 'Nunito', sans-serif;
  margin-top: 30px; 

}

  /* End of Sign Up Home Container */


  /* Style for other pages */

.account-style {
    width: 400px; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-top: 450px; /* Consistent margin from top */
    margin-left: auto; /* Centering the form horizontally */
    margin-right: auto;
  }
  
  .account-container {
    width: 100%;
    max-width: 400px; /* example width */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 50px;
    padding-top: 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
   
    align-items: center;
  }
  
  .account-style h2 {
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    color: #2c3e50;
  }
  
  .account-style label {
    display: block;
    margin-top: 10px;
    color: #2c3e50;
    text-align: left;
    margin-bottom: 3px;
    font-family: 'Nunito', sans-serif;
  }
  
  .account-style input[type="text"], 
  .account-style input[type="password"], 
  .account-style input[type="email"] {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .account-style button {
    width: auto;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #fc8320;
    color: white;
    text-transform: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin: 20px auto;


    
    
    
   
    
  }
  
  .account-style button:hover {
    background-color: #951eb5;
  }
  
  .account-style name-display {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .account-style name-display label {
    margin-right: 10px;
  }
  
  .account-style email-edit {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .account-style email-edit input, .email-edit span {
    margin-right: 10px;
  }
  
  .account-style edit-icon {
    cursor: pointer;
    color: #3498db;
  }



 

  .subhead { /* subheader for signup forms  */
    font-family: 'Nunito Sans', sans-serif;
    font-size: 13px;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 8px;
  } 

  .alert-message { /* Password requirements for signup forms.  */
    font-family: 'Nunito', sans-serif;
    font-size: 11px; 
    color: #fc8320;
    font-weight: bold;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 1px;
    padding-top: 0px; 
  
  }





   /* MEDIA QUERIES */

   @media only screen and (max-width: 480px) { 

    /* example Galaxy S5 + Iphone SE */
     /* Sign Up Home Container */

     .container-style { /* background */
      width: 100%; /* Fixed width */
      height: auto; /* Adjusted for variable content */
      display: flex;
      margin-top: 20px; /* Consistent margin from top */
    }


    .sign-home {
      margin-top: 125px;
      margin-left: 50px;
      margin-right: 70px;
      justify-content: center; /* Centers the content horizontally */
      align-items: start; /* Aligns items to the top */
      padding: 20px;
      flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
  }

        .container {
          display: flex;
          margin-top: 125px;
          margin-left: 100px;
          margin-right: 190px;
          justify-content: center; /* Centers the content horizontally */
          align-items: start; /* Aligns items to the top */
          padding: 20px;
          flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
      }

      .image-container {  /* media query */
          
        display: none;
        

    }

        .image-container img {  /* media query */
          display: none;
      }

        .custom-select { /* media query */
          padding: 10px;
          margin-top: 20px; /* Spacing between elements */
          margin-bottom: 20px; /* Spacing between elements */
          font-size: 16px;
          font-weight: 200;
        }
        
        .custom-select:hover { /* media query */
          background-color: #941eb51f;
        }



          .cta-links { /* media query */
          
            margin-top: 10px; 

      }


      .account-style {  /* Media query.  */
        width: 60%; /* Fixed width */ /* Fixed width */
        height: 100%; /* Adjusted for variable content */ /* Adjusted for variable content */
        border-radius: 20px;
        padding: 40px; /* Consistent padding */
        display: flex;
        flex-direction: column;
        margin-top: 170%;
        margin-left: auto; /* Centering the form horizontally */
        margin-right: auto;
    }
    
      .account-container {  /* Media query.  */
        max-width: 100px; /* example width */
        border-radius: 20px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    body 
    {
    min-height: 100%;
    padding-bottom: 600px; /* Add bottom padding */
    overflow-x: hidden;
  }
    

        .account-style h2 {  /* Media query.  */
          text-align: center;
          font-size: 20px;
    }
    
    .account-style label {  /* Media query.  */
      display: block;
      margin-top: 10px;
      font-size: 14px;
      color: #2c3e50;
    
    }
    
   
    
    .submit-button {  /* Media query.  */
      width: 70%;
      padding: 10px;
      margin-top: 10px;
      background-color: #fc8320;
      justify-content: center;
      
    }

    
    .account-style name-display label {  /* Media query.  */
      margin-right: 10px;
      font-size: 14px;
    }
    
    .account-style email-edit {  /* Media query.  */
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 10px;
    }
    

    
   }


   @media only screen and (min-width: 481px) and (max-width: 640px) {
 
    .container-style { /* background */
      width: 100%; /* Fixed width */
      height: auto; /* Adjusted for variable content */
      display: flex;
      margin-top: 20px; /* Consistent margin from top */
    }


    .sign-home {
      margin-top: 125px;
      margin-left: 50px;
      margin-right: 70px;
      justify-content: center; /* Centers the content horizontally */
      align-items: start; /* Aligns items to the top */
      padding: 20px;
      flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
  }

      .container {
        display: flex;
        margin-top: 125px;
        margin-left: 100px;
        margin-right: 190px;
        justify-content: center; /* Centers the content horizontally */
        align-items: start; /* Aligns items to the top */
        padding: 20px;
        flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
    }

    .image-container {  /* media query */
        
      display: none;
      

    }

      .image-container img {  /* media query */
        display: none;
    }

      .custom-select { /* media query */
        padding: 10px;
        margin-top: 20px; /* Spacing between elements */
        margin-bottom: 20px; /* Spacing between elements */
        font-size: 16px;
        font-weight: 200;
      }
      
      .custom-select:hover { /* media query */
        background-color: #941eb51f;
      }



        .cta-links { /* media query */
        
          margin-top: 10px; 

    }


      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {

        /* example iPad  */

      }


   @media only screen and (min-width: 1024px) and (max-width: 1366px) {


    /* Media query.  */

    .account-style {  /* Media query.  */
      width: 100%; /* Fixed width */
      height: 80%; /* Adjusted for variable content */
      border-radius: 20px;
      padding: 50px; /* Consistent padding */
      display: flex;
      flex-direction: column;
      margin-top: 100px; /* Consistent margin from top */
      margin-left: auto; /* Centering the form horizontally */
      margin-right: auto;
    }

    .account-style label {  /* Media query.  */
      display: block;
      margin-top: 10px;
      font-size: 20px;
      color: #2c3e50;
    
    }

    .account-style h2 {  /* Media query.  */
      text-align: center;
      font-size: 30px;
    }


   }




@media only screen and (min-width: 768px) and (max-width: 1023px) {

  /* example iPad  */


  .custom-select { /* Media query main */
    padding: 10px;
    margin-top: 20px; /* Spacing between elements */
    margin-bottom: 20px; /* Spacing between elements */
    background-color: #fc832030;
    border-radius: 10px;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: #2c3e50;
  }

  .image-container { /* Media query main */
    flex: 1; /* Takes the remaining space */
    display: flex;
    padding-top: 10;
    justify-content: center;
    align-items: center;
    max-width: 200px; /* Sets a maximum width to match the form */
    margin: 20px; /* Adds space around the image */
}

.image-container img { /* Media query main */
    max-width: 100%;
    margin-top: 0px; /* Consistent margin from top */
    margin-right: 150px; /* Consistent margin from top */
    height: 500px;
    max-height: 200px; /* Adjust to fit the design */
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
 

  .container {
    display: flex;
    margin-top: 100px;
    margin-left: 190px;
    justify-content: center; /* Centers the content horizontally */
    align-items: start; /* Aligns items to the top */
    padding: 20px;
    flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
}

  .custom-select {
    padding: 10px;
    margin-top: 15px; /* Spacing between elements */
    margin-bottom: 15px; /* Spacing between elements */
    background-color: #fc832030;
    border-radius: 10px;
    border: none;;
    font-size: 20px;
    font-weight: 200;
    color: #2c3e50;
  }

  .signup h2 {
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    font-size: 30px;
    color: #2c3e50;
  }

  p {
    font-size: 20px;
  }

 
}